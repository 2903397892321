import { useMemo, useState } from 'react';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { Drawer, Button, Modal, message } from 'antd';
import type { DrawerProps } from 'antd';
import { Input, Select } from '@formily/antd-v5';
import { deleteDictData, getDictDataList } from 'src/api/clients/system';
import { IDictDataItem, IGetDictDataListReq } from 'src/api/types/system';
import { QueryTable, QueryTableColumnProps } from 'src/components/QueryTable';
import { dataColumns, queryDataSchema } from './schema';
import { DictDataModal } from './dict-data-modal';

interface IProps extends DrawerProps {
  dictType?: string;
  dictName?: string;
}

const QueryTableSystemDictData = 'QueryTableSystemDictData';
export function DictDataDrawer(props: IProps) {
  const { open, onClose, dictName, dictType, ...extra } = props;
  const [visible, { setTrue, setFalse }] = useBoolean(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [currentData, setCurrentData] = useState<IDictDataItem>();

  const getDictDataListTable = useMemoizedFn(async (params: IGetDictDataListReq) => {
    const result = await getDictDataList({ ...params, dictType });
    return {
      data: result.rows,
      total: result.count,
    };
  });
  const onDeleteRoleByIds = useMemoizedFn(async (ids: number[]) => {
    Modal.confirm({
      title: '注意',
      content: `删除后无法恢复，确定删除指定字典吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await deleteDictData(ids);
        message.success('删除成功');
        onRefresh();
      },
    });
  });
  const onRefresh = useMemoizedFn((payload: Record<string, any> = {}) => {
    QueryTable.ProviderGlobalDispatch(
      {
        type: QueryTable.ActionEnum.REQUEST_START,
        payload,
      },
      QueryTableSystemDictData,
    );
  });

  const totalColumn = useMemo(() => {
    return [
      ...dataColumns,
      {
        title: '操作',
        fixed: 'right',
        width: 150,
        renderType: 'Operation',
        renderProps: (col: IDictDataItem) => {
          return {
            buttons: [
              {
                label: '编辑',
                onClick: () => {
                  setCurrentData(col);
                  setTrue();
                },
              },
              {
                label: '删除',
                onClick: async () => {
                  await onDeleteRoleByIds([col.dictDataId]);
                },
              },
            ],
          };
        },
      },
    ] as QueryTableColumnProps;
  }, [dataColumns]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onCancelHandle = useMemoizedFn((e) => {
    onClose?.(e);
  });

  return (
    <Drawer
      width={800}
      {...extra}
      open={open}
      destroyOnClose
      onClose={onCancelHandle}
      title={`字典数据管理-${dictName}`}>
      <>
        <QueryTable.Provider
          useQuery={false}
          name={QueryTableSystemDictData}
          requestFn={getDictDataListTable}>
          <QueryTable.Filter schema={queryDataSchema} components={{ Input, Select }} />
          <QueryTable.Table
            rowKey="dictDataId"
            columns={totalColumn}
            scroll={{ x: 1200 }}
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
            }}
            tabsNode={
              <Button type="primary" onClick={setTrue}>
                新增数据
              </Button>
            }
          />
        </QueryTable.Provider>
        <DictDataModal
          data={currentData}
          open={visible}
          dictType={dictType}
          onOk={onRefresh}
          onCancel={() => {
            setFalse();
            setCurrentData(undefined);
          }}
        />
      </>
    </Drawer>
  );
}
